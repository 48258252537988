import { auth } from '../config/firebaseConfig'
import { store } from '../store'
import bus from '../utils/bus'
import { getAuth } from 'firebase/auth'

import Vue from 'vue'
import Router, {
  Location,
  NavigationGuard,
  RawLocation,
  Route,
} from 'vue-router'
import IndexPage from '../components/Index.vue'
import IndexMobilePage from '../components/IndexMobile.vue'
import ReloadPage from '../components/Reload.vue'
import { User } from 'firebase/auth'
import Storage from '../lib/storage'

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve?, onReject?) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return (originalPush.call(this, location) as any).catch((err) => {
    if (Router.isNavigationFailure(err)) {
      // resolve err
      return err
    }
    // rethrow error
    return Promise.reject(err)
  })
}

Vue.use(Router)

const PAGE_REFRESHED_KEY = 'pageRefreshed'

export const retryImport =
  (componentImport: () => Promise<any>) => async () => {
    const pageAlreadyRefreshed = Storage.getBoolean(PAGE_REFRESHED_KEY)
    try {
      const component = await componentImport()
      Storage.saveBoolean(PAGE_REFRESHED_KEY, false)
      return component
    } catch (error) {
      if (!pageAlreadyRefreshed) {
        Storage.saveBoolean(PAGE_REFRESHED_KEY, true)
        return ReloadPage
      }
      throw error
    }
  }

/**
 * @param to - route to be converted
 * @param isMobilePath - whether the path is a mobile path
 * @returns converted route
 */
function navigationPath(to: Route, isMobilePath: boolean): Location {
  const path = isMobilePath ? to.path.replace(/_m$/, '') : `${to.path}_m`

  return {
    hash: to.hash,
    query: to.query,
    path,
    params: to.params,
  }
}

/**
 * @param dispatch - dispatcher to be called when the user is authenticated
 * @returns navigation guard
 */
function navigationGuard(
  dispatch: (
    to: Route,
    from: Route,
    next: (to?: RawLocation) => void,
    user: User,
  ) => Promise<void>,
): NavigationGuard {
  return async (to, from, next) => {
    const isMobilePath = to.path.endsWith('_m')
    if (store.state.isMobile !== isMobilePath) {
      return next(navigationPath(to, isMobilePath))
    }

    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      await dispatch(to, from, next, user)

      if (unsubscribe) unsubscribe()
    })
  }
}

function navigationAuthGuard(
  dispatch?: (user: User) => Promise<void>,
): NavigationGuard {
  return navigationGuard(async (to, _from, next, user) => {
    const isMobilePath = to.path.endsWith('_m')
    if (user) {
      await dispatch?.(user)
      next()
    } else {
      store.commit('setRecordRouter', to.fullPath)
      return next(isMobilePath ? '/login_m' : '/login')
    }
  })
}

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/index',
    },
    {
      path: '/index',
      name: 'Index',
      component: IndexPage,
      beforeEnter: async (to, from, next) => {
        const user = getAuth().currentUser
        if (user) {
          if (
            store.state.todayDeliveryGoods === null &&
            store.state.tomorrowDeliveryGoods === null
          ) {
            await store.dispatch('fetchCustomerData', user.uid)
            await store.dispatch('loadAvailableGoodsList')
          }
        }
        next()
      },
    },
    {
      path: '/index_m',
      name: 'IndexMobile',
      component: IndexMobilePage,
      beforeEnter: async (to, from, next) => {
        const user = getAuth().currentUser
        if (user) {
          if (
            store.state.todayDeliveryGoods === null &&
            store.state.tomorrowDeliveryGoods === null
          ) {
            await store.dispatch('fetchCustomerData', user.uid)
            await store.dispatch('loadAvailableGoodsList')
          }
        }
        next()
      },
    },
    {
      path: '/delivery',
      name: 'Delivery',
      component: retryImport(() => import('../components/Delivery.vue')),
    },
    {
      path: '/delivery_m',
      name: 'DeliveryMobile',
      component: retryImport(() => import('../components/DeliveryMobile.vue')),
    },
    {
      path: '/appbridge_m',
      name: 'AppBridgeMobile',
      component: retryImport(() => import('../components/AppBridgeMobile.vue')),
    },
    {
      path: '/giftcard_m',
      name: 'GiftCardMobile',
      component: retryImport(() => import('../components/GiftCardMobile.vue')),
    },
    {
      path: '/giftcard',
      name: 'GiftCard',
      component: retryImport(() => import('../components/GiftCard.vue')),
    },
    {
      path: '/login',
      name: 'Login',
      component: retryImport(() => import('../components/Login.vue')),
    },
    {
      path: '/login_m',
      name: 'LoginMobile',
      component: retryImport(() => import('../components/LoginMobile.vue')),
    },
    {
      path: '/naver-signin-redirect',
      name: 'LoginRedirectNaver',
      component: retryImport(
        () => import('../components/LoginRedirectNaver.vue'),
      ),
    },
    {
      path: '/naver-signin-redirect_m',
      name: 'LoginRedirectNaverMobile',
      component: retryImport(
        () => import('../components/LoginRedirectNaverMobile.vue'),
      ),
    },
    {
      path: '/mypage',
      name: 'Mypage',
      component: retryImport(() => import('../components/Mypage.vue')),
      beforeEnter: navigationAuthGuard(async (user) => {
        bus.$emit('start:spinner')
        await store.dispatch('fetchCustomerData', user.uid)
      }),
    },
    {
      path: '/mypage_m',
      name: 'MypageMobile',
      component: retryImport(() => import('../components/MypageMobile.vue')),
      beforeEnter: navigationAuthGuard(async (user) => {
        bus.$emit('start:spinner')
        await store.dispatch('fetchCustomerData', user.uid)
      }),
    },
    {
      path: '/detail',
      name: 'Detail',
      component: retryImport(() => import('../components/Detail.vue')),
      beforeEnter: navigationGuard(async (to, _from, next, user) => {
        bus.$emit('start:spinner')

        const token = (await user?.getIdToken()) ?? ''

        const response = await fetch(
          `https://api.yookgak.com/ecommerce/goods/api/v1/goodslist/${to.query.product}/couponlist`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-ID-TOKEN': token,
            },
          },
        )

        store.commit('setProductCouponList', await response.json())
        bus.$emit('end:spinner')
        next()
      }),
    },
    {
      path: '/detail_m',
      name: 'DetailMobile',
      component: retryImport(() => import('../components/DetailMobile.vue')),
      beforeEnter: navigationGuard(async (to, _from, next, user) => {
        bus.$emit('start:spinner')

        const token = (await user?.getIdToken()) ?? ''

        const response = await fetch(
          `https://api.yookgak.com/ecommerce/goods/api/v1/goodslist/${to.query.product}/couponlist`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'X-USER-ID-TOKEN': token,
            },
          },
        )

        store.commit('setProductCouponList', await response.json())
        bus.$emit('end:spinner')
        next()
      }),
    },
    {
      path: '/detailbridge',
      name: 'DetailBridge',
      component: retryImport(() => import('../components/DetailBridge.vue')),
    },
    {
      path: '/cart',
      name: 'Cart',
      component: retryImport(() => import('../components/Cart.vue')),
      beforeEnter: navigationAuthGuard(async (user) => {
        const uid = user.uid
        const due = 7
        await store.dispatch('checkFirstgiftTarget', { uid, due })
      }),
    },
    {
      path: '/cart_m',
      name: 'CartMobile',
      component: retryImport(() => import('../components/CartMobile.vue')),
      beforeEnter: navigationAuthGuard(async (user) => {
        const uid = user.uid
        const due = 7
        await store.dispatch('checkFirstgiftTarget', { uid, due })
      }),
    },
    {
      path: '/payment',
      name: 'Payment',
      component: retryImport(() => import('../components/Payment.vue')),
      beforeEnter: navigationAuthGuard((user) =>
        store.dispatch('fetchCustomerData', user.uid),
      ),
    },
    {
      path: '/payment_m',
      name: 'PaymentMobile',
      component: retryImport(() => import('../components/PaymentMobile.vue')),
      beforeEnter: navigationAuthGuard((user) =>
        store.dispatch('fetchCustomerData', user.uid),
      ),
    },
    {
      path: '/brandstory',
      name: 'Brandstory',
      component: retryImport(() => import('../components/Brandstory.vue')),
    },
    {
      path: '/brandstory_m',
      name: 'BrandstoryMobile',
      component: retryImport(
        () => import('../components/BrandstoryMobile.vue'),
      ),
    },
    {
      path: '/event',
      name: 'Event',
      component: retryImport(() => import('../components/EventList.vue')),
    },
    {
      path: '/event_m',
      name: 'EventMobile',
      component: retryImport(() => import('../components/EventListMobile.vue')),
    },
    {
      path: '/eventdetail',
      name: 'EventDetail',
      component: retryImport(() => import('../components/EventDetail.vue')),
    },
    {
      path: '/eventdetail_m',
      name: 'EventDetailMobile',
      component: retryImport(
        () => import('../components/EventDetailMobile.vue'),
      ),
    },
    {
      path: '/appeventdetail_m',
      name: 'EventDetailApp',
      component: retryImport(() => import('../components/EventDetailApp.vue')),
    },
    {
      path: '/eventtest',
      name: 'EventTest',
      component: retryImport(() => import('../components/EventDetailNew.vue')),
    },
    {
      path: '/eventtest_m',
      name: 'EventTestMobile',
      component: retryImport(
        () => import('../components/EventDetailNewMobile.vue'),
      ),
    },
    {
      path: '/friendlink',
      name: 'EventFriendLink',
      component: retryImport(() => import('../components/EventFriendLink.vue')),
    },
    {
      path: '/friendlink_m',
      name: 'EventFriendLinkMobile',
      component: retryImport(
        () => import('../components/EventFriendLinkMobile.vue'),
      ),
    },
    {
      path: '/list',
      name: 'List',
      component: retryImport(() => import('../components/List.vue')),
    },
    {
      path: '/list_m',
      name: 'ListMobile',
      component: retryImport(() => import('../components/ListMobile.vue')),
    },
    {
      path: '/guide',
      name: 'Guide',
      component: retryImport(() => import('../components/Guide.vue')),
    },
    {
      path: '/guide_m',
      name: 'GuideMobile',
      component: retryImport(() => import('../components/GuideMobile.vue')),
    },
    {
      path: '/notify',
      name: 'Notify',
      component: retryImport(() => import('../components/Notify.vue')),
    },
    {
      path: '/notify_m',
      name: 'NotifyMobile',
      component: retryImport(() => import('../components/NotifyMobile.vue')),
    },
    {
      path: '/notice',
      name: 'Notice',
      component: retryImport(() => import('../components/Notice.vue')),
    },
    {
      path: '/notice_m',
      name: 'NoticeMobile',
      component: retryImport(() => import('../components/NoticeMobile.vue')),
    },
    {
      path: '/appnotice_m',
      name: 'NoticeApp',
      component: retryImport(() => import('../components/NoticeApp.vue')),
    },
    {
      path: '/support',
      name: 'Support',
      component: retryImport(() => import('../components/Support.vue')),
      beforeEnter: navigationGuard(async (to, _from, next, user) => {
        if (to.query.view === 'question') {
          if (user) {
            next()
          } else {
            store.commit('setRecordRouter', to.fullPath)
            next('/login')
          }
        } else {
          await store.dispatch('fetchFaq')
          next()
        }
      }),
    },
    {
      path: '/support_m',
      name: 'SupportMobile',
      component: retryImport(() => import('../components/SupportMobile.vue')),
      beforeEnter: navigationGuard(async (to, _from, next, user) => {
        if (to.query.view === 'question') {
          if (user) {
            next()
          } else {
            store.commit('setRecordRouter', to.fullPath)
            next('/login_m')
          }
        } else {
          await store.dispatch('fetchFaq')
          next()
        }
      }),
    },
    {
      path: '/withdrawal',
      name: 'Withdrawal',
      component: retryImport(() => import('../components/Withdrawal.vue')),
      beforeEnter: navigationAuthGuard(),
    },
    {
      path: '/withdrawal_m',
      name: 'WithdrawalMobile',
      component: retryImport(
        () => import('../components/WithdrawalMobile.vue'),
      ),
      beforeEnter: navigationAuthGuard(),
    },
    {
      path: '/viewquestion',
      name: 'ViewQuestion',
      component: retryImport(() => import('../components/ViewQuestion.vue')),
      beforeEnter: navigationAuthGuard(),
    },
    {
      path: '/viewquestion_m',
      name: 'ViewQuestionMobile',
      component: retryImport(
        () => import('../components/ViewQuestionMobile.vue'),
      ),
      beforeEnter: navigationAuthGuard(),
    },
    {
      path: '/writereview',
      name: 'WriteReview',
      component: retryImport(() => import('../components/WriteReview.vue')),
      beforeEnter: navigationAuthGuard(),
    },
    {
      path: '/writereview_m',
      name: 'WriteReviewMobile',
      component: retryImport(
        () => import('../components/WriteReviewMobile.vue'),
      ),
      beforeEnter: navigationAuthGuard(),
    },
    {
      path: '/viewreview',
      name: 'ViewReview',
      component: retryImport(() => import('../components/ViewReview.vue')),
      beforeEnter: navigationAuthGuard(),
    },
    {
      path: '/viewreview_m',
      name: 'ViewReviewMobile',
      component: retryImport(
        () => import('../components/ViewReviewMobile.vue'),
      ),
      beforeEnter: navigationAuthGuard(),
    },
    {
      path: '/register',
      name: 'Register',
      component: retryImport(() => import('../components/Register.vue')),
    },
    {
      path: '/register_m',
      name: 'RegisterMobile',
      component: retryImport(() => import('../components/RegisterMobile.vue')),
    },
    {
      path: '/naverpay',
      name: 'PayNaver',
      component: retryImport(() => import('../components/PayNaver.vue')),
    },
    {
      path: '/naverpay_m',
      name: 'PayNaverMobile',
      component: retryImport(() => import('../components/PayNaverMobile.vue')),
    },
    {
      path: '/identify',
      name: 'Identify',
      component: retryImport(() => import('../components/Identify.vue')),
    },
    {
      path: '/identify_m',
      name: 'IdentifyMobile',
      component: retryImport(() => import('../components/IdentifyMobile.vue')),
    },
    {
      path: '/pwreset_m',
      name: 'PwresetMobile',
      component: retryImport(() => import('../components/PwresetMobile.vue')),
    },
    {
      path: '/pwreset',
      name: 'Pwreset',
      component: retryImport(() => import('../components/Pwreset.vue')),
    },
    {
      path: '/private',
      name: 'Private',
      component: retryImport(() => import('../components/Private.vue')),
    },
    {
      path: '/private_m',
      name: 'PrivateMobile',
      component: retryImport(() => import('../components/PrivateMobile.vue')),
    },
    {
      path: '/term',
      name: 'Term',
      component: retryImport(() => import('../components/Term.vue')),
    },
    {
      path: '/term_m',
      name: 'TermMobile',
      component: retryImport(() => import('../components/TermMobile.vue')),
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'Error404',
      component: retryImport(() => import('../components/Error404.vue')),
    },
  ],

  scrollBehavior(_to, from, savedPosition) {
    if (savedPosition) {
      if (from.name !== 'Detail' && from.name !== 'DetailMobile') {
        return savedPosition
      }
    }
  },
})
